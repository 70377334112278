import { Typography } from "@mui/material";
import { theme } from "@talkouttech/portal-shared";
import { ReactComponent as ImportantIcon } from "assets/ImportantIconNotifications.svg";
import { ReactComponent as KudosIcon } from "assets/Kudos.svg";
import { ReactComponent as ShareIcon } from "assets/Share.svg";
import { NotificationCard } from "components/Notifications/NotificationsCard";
import {
  NotificationGroupAvatar,
  SmallAvatarBadge
} from "components/Notifications/NotificationsCard/NotificationCard.styles";
import {
  NotificationGroupKudosMessage,
  NotificationsBadgePostMessage,
  NotificationsPostMessage
} from "components/Notifications/NotificationsMessageSection";
import { ConfiguredReaction } from "domain/features";
import {
  BaseMetaData,
  CourseAssignemtnsMetaData,
  EarnedBadgeMetaData,
  FollowersPostAudienceMetaData,
  GroupInvitationMetaData,
  KnowledgeRequestMetaData,
  KudosPostMetaData,
  MandatoryPostMetaData,
  NotificationData,
  NotificationLinkType,
  NotificationMetaData,
  NotificationStatus,
  NotificationType,
  PostAudienceMetaData,
  PostCommentMetaData,
  PostCommentReactionMetaData,
  PostMentionedMetaData,
  PostMetaData,
  PostReactionMetaData,
  PostSharedMetaData
} from "domain/notifications";
import { ReactionEmoji } from "presentation/NotificationsCenter/components/NotificationsSection/NotificationsSection.styles";
import { TFunction, Trans } from "react-i18next";
import { MdGroupAdd, MdMessage } from "react-icons/md";
import { mapReactionToKey } from "utils/post-utils";

export const notificationsResultFactory = (
  t: TFunction<"translation", undefined>,
  handleNotificationsNavigation: (
    linkType: string,
    id: string,
    postId?: string,
    metadata?: NotificationMetaData
  ) => () => void,
  configReactions?: ConfiguredReaction[]
) => ({
  [NotificationType.Chat]: (result: NotificationData) => {
    return null;
  },
  [NotificationType.Post]: (result: NotificationData) => {
    const isRead = result.status === NotificationStatus.Read ?? false;
    const { avatarUrl, displayName } = result.metadata as PostMetaData;
    const { id, type, linkType, linkId, _createdOn, updatedOn } = result;
    return (
      <NotificationCard
        key={id}
        read={isRead}
        onClick={handleNotificationsNavigation(linkType, id, linkId)}
        notificationBadge={null}
        avatarUrl={avatarUrl ?? ""}
        notificationMessage={
          <NotificationsPostMessage displayName={displayName ?? ""} message={t(`notifications.${type}`)} />
        }
        _createdOn={_createdOn}
        updatedOn={updatedOn}
      />
    );
  },
  [NotificationType.MandatoryPost]: (result: NotificationData) => {
    const isRead = result.status === NotificationStatus.Read ?? false;
    const { avatarUrl, postedByName } = result.metadata as MandatoryPostMetaData;
    const { id, type, linkType, linkId, _createdOn, updatedOn } = result;
    return (
      <NotificationCard
        key={id}
        read={isRead}
        onClick={handleNotificationsNavigation(linkType, id, linkId)}
        notificationBadge={
          <SmallAvatarBadge>
            <ImportantIcon />
          </SmallAvatarBadge>
        }
        avatarUrl={avatarUrl ?? ""}
        notificationMessage={
          <NotificationsBadgePostMessage displayName={postedByName ?? ""} message={t(`notifications.${type}`)} />
        }
        _createdOn={_createdOn}
        updatedOn={updatedOn}
      />
    );
  },
  [NotificationType.Course]: (result: NotificationData) => {
    const isRead = result.status === NotificationStatus.Read ?? false;
    const { courseName } = result.metadata as CourseAssignemtnsMetaData;
    const { id, linkType, linkId, _createdOn, updatedOn } = result;
    return (
      <NotificationCard
        key={id}
        read={isRead}
        onClick={handleNotificationsNavigation(linkType, id, linkId)}
        notificationBadge={
          <SmallAvatarBadge>
            <ImportantIcon />
          </SmallAvatarBadge>
        }
        avatarUrl={""}
        notificationMessage={
          <Typography>
            <Trans i18nKey="notifications.courseAssigned" values={{ courseName: courseName ?? "" }}>
              You have been assigned the course
              <span style={{ fontWeight: "bold" }}></span>
            </Trans>
          </Typography>
        }
        _createdOn={_createdOn}
        updatedOn={updatedOn}
      />
    );
  },
  [NotificationType.CourseReAssigned]: (result: NotificationData) => {
    const isRead = result.status === NotificationStatus.Read ?? false;
    const { courseName } = result.metadata as CourseAssignemtnsMetaData;
    const { id, linkId, _createdOn, updatedOn } = result;
    return (
      <NotificationCard
        key={id}
        read={isRead}
        onClick={handleNotificationsNavigation(NotificationLinkType.Course, id, linkId)}
        notificationBadge={
          <SmallAvatarBadge>
            <ImportantIcon />
          </SmallAvatarBadge>
        }
        avatarUrl={""}
        notificationMessage={
          <Typography>
            <Trans i18nKey="notifications.courseReAssigned" values={{ courseName: courseName ?? "" }}>
              Your assigned course <span style={{ fontWeight: "bold" }}></span>, has been updated, tap to complete it
            </Trans>
          </Typography>
        }
        _createdOn={_createdOn}
        updatedOn={updatedOn}
      />
    );
  },
  [NotificationType.PostMentioned]: (result: NotificationData) => {
    const isRead = result.status === NotificationStatus.Read ?? false;
    const { avatarUrl, displayName, postId } = result.metadata as PostMentionedMetaData;
    const { id, type, linkType, _createdOn, updatedOn } = result;
    return (
      <NotificationCard
        key={id}
        read={isRead}
        onClick={handleNotificationsNavigation(linkType, id, postId)}
        notificationBadge={null}
        avatarUrl={avatarUrl ?? ""}
        notificationMessage={
          <NotificationsPostMessage displayName={displayName ?? ""} message={t(`notifications.${type}`)} />
        }
        _createdOn={_createdOn}
        updatedOn={updatedOn}
      />
    );
  },
  [NotificationType.PostMentionedAdded]: (result: NotificationData) => {
    const isRead = result.status === NotificationStatus.Read ?? false;
    const { avatarUrl, displayName, postId } = result.metadata as PostMentionedMetaData;
    const { id, type, linkType, _createdOn, updatedOn } = result;
    return (
      <NotificationCard
        key={id}
        read={isRead}
        onClick={handleNotificationsNavigation(linkType, id, postId)}
        notificationBadge={null}
        avatarUrl={avatarUrl ?? ""}
        notificationMessage={
          <NotificationsPostMessage displayName={displayName ?? ""} message={t(`notifications.${type}`)} />
        }
        _createdOn={_createdOn}
        updatedOn={updatedOn}
      />
    );
  },
  [NotificationType.PostAudience]: (result: NotificationData) => {
    const isRead = result.status === NotificationStatus.Read ?? false;
    const { avatarUrl, postedByName, audienceName } = result.metadata as PostAudienceMetaData;
    const isKnowledgeRequest = (result.metadata as BaseMetaData).postType === "knowledgeRequest";
    const { id, type, linkType, linkId, _createdOn, updatedOn } = result;
    return (
      <NotificationCard
        key={id}
        read={isRead}
        onClick={handleNotificationsNavigation(linkType, id, linkId)}
        notificationBadge={null}
        avatarUrl={avatarUrl ?? ""}
        notificationMessage={
          <NotificationGroupKudosMessage
            displayName={postedByName ?? ""}
            message={isKnowledgeRequest ? t("notifications.krnew") : t(`notifications.${type}`)}
            receiverName={audienceName ?? ""}
          />
        }
        _createdOn={_createdOn}
        updatedOn={updatedOn}
      />
    );
  },
  [NotificationType.FollowersAudience]: (result: NotificationData) => {
    const isRead = result.status === NotificationStatus.Read ?? false;
    const { avatarUrl, postedByName } = result.metadata as FollowersPostAudienceMetaData;
    const { id, type, linkType, linkId, _createdOn, updatedOn } = result;
    return (
      <NotificationCard
        key={id}
        read={isRead}
        onClick={handleNotificationsNavigation(linkType, id, linkId)}
        notificationBadge={null}
        avatarUrl={avatarUrl ?? ""}
        notificationMessage={
          <NotificationsBadgePostMessage displayName={postedByName ?? ""} message={t(`notifications.${type}`)} />
        }
        _createdOn={_createdOn}
        updatedOn={updatedOn}
      />
    );
  },
  [NotificationType.PostComment]: (result: NotificationData) => {
    const isRead = result.status === NotificationStatus.Read ?? false;

    const isCommentReply = result.subtype !== undefined && result.subtype === "reply";

    const isKnowledgeRequest = (result.metadata as BaseMetaData).postType === "knowledgeRequest";
    let metaData;

    if (isKnowledgeRequest) {
      metaData = result.metadata as KnowledgeRequestMetaData;
    } else {
      metaData = result.metadata as PostCommentMetaData;
    }

    const { id, type, linkType, _createdOn, updatedOn } = result;
    return (
      <NotificationCard
        key={id}
        read={isRead}
        onClick={handleNotificationsNavigation(linkType, id, metaData.postId)}
        backgroundColor={theme.colors.success}
        notificationBadge={
          !isKnowledgeRequest && (
            <SmallAvatarBadge>
              <MdMessage size={12} />
            </SmallAvatarBadge>
          )
        }
        avatarUrl={metaData.avatarUrl ?? ""}
        notificationMessage={
          <NotificationsPostMessage
            displayName={metaData.postedByName ?? ""}
            message={
              isKnowledgeRequest
                ? isCommentReply || (metaData as KnowledgeRequestMetaData).replyToId
                  ? t("notifications.krRepliesForAnswer")
                  : t("notifications.krAnsweredYourRequest")
                : t(`notifications.${type}`)
            }
          />
        }
        _createdOn={_createdOn}
        updatedOn={updatedOn}
      />
    );
  },
  [NotificationType.GroupInvite]: (result: NotificationData) => {
    const isRead = result.status === NotificationStatus.Read ?? false;
    const { avatarUrl, invitedByName, displayName, invitedByAvatar, postId } =
      result.metadata as GroupInvitationMetaData;

    const { id, type, linkType, linkId, _createdOn, updatedOn } = result;
    return (
      <NotificationCard
        key={id}
        read={isRead}
        onClick={handleNotificationsNavigation(linkType, id, linkId)}
        notificationBadge={
          <SmallAvatarBadge>
            <MdGroupAdd size={12} color={theme.colors.black} />
          </SmallAvatarBadge>
        }
        avatarUrl={avatarUrl ?? ""}
        notificationMessage={
          <NotificationGroupKudosMessage
            displayName={invitedByName ?? ""}
            message={t(`notifications.${type}`)}
            receiverName={displayName ?? ""}
          />
        }
        _createdOn={_createdOn}
        updatedOn={updatedOn}
        rightComponent={<NotificationGroupAvatar src={invitedByAvatar ?? ""} alt={postId ?? ""} variant={"rounded"} />}
      />
    );
  },
  [NotificationType.PostShared]: (result: NotificationData) => {
    const isRead = result.status === NotificationStatus.Read ?? false;
    const { avatarUrl, displayName } = result.metadata as PostSharedMetaData;

    const { id, type, linkType, linkId, _createdOn, updatedOn } = result;
    return (
      <NotificationCard
        key={id}
        read={isRead}
        onClick={handleNotificationsNavigation(linkType, id, linkId)}
        backgroundColor={theme.colors.purple}
        notificationBadge={
          <SmallAvatarBadge>
            <ShareIcon />
          </SmallAvatarBadge>
        }
        avatarUrl={avatarUrl ?? ""}
        notificationMessage={
          <NotificationsPostMessage displayName={displayName ?? ""} message={t(`notifications.${type}`)} />
        }
        _createdOn={_createdOn}
        updatedOn={updatedOn}
      />
    );
  },
  [NotificationType.KudosPost]: (result: NotificationData) => {
    const isRead = result.status === NotificationStatus.Read ?? false;
    const { avatarUrl, postedByName, kudosName } = result.metadata as KudosPostMetaData;

    const { id, type, linkType, linkId, _createdOn, updatedOn } = result;
    return (
      <NotificationCard
        key={id}
        read={isRead}
        onClick={handleNotificationsNavigation(linkType, id, linkId)}
        backgroundColor={theme.colors.white}
        notificationBadge={
          <SmallAvatarBadge>
            <KudosIcon />
          </SmallAvatarBadge>
        }
        avatarUrl={avatarUrl ?? ""}
        notificationMessage={
          <NotificationGroupKudosMessage
            displayName={postedByName ?? ""}
            message={t(`notifications.${type}`)}
            receiverName={kudosName ?? ""}
          />
        }
        _createdOn={_createdOn}
        updatedOn={updatedOn}
      />
    );
  },
  [NotificationType.PostCommentReaction]: (result: NotificationData) => {
    const isRead = result.status === NotificationStatus.Read ?? false;
    const isKnowledgeRequest = (result.metadata as BaseMetaData).postType === "knowledgeRequest";
    let metaData;

    if (isKnowledgeRequest) {
      metaData = result.metadata as KnowledgeRequestMetaData;
    } else {
      metaData = result.metadata as PostCommentReactionMetaData;
    }

    const { id, type, linkType, _createdOn, updatedOn } = result;
    return (
      <NotificationCard
        key={id}
        read={isRead}
        onClick={handleNotificationsNavigation(linkType, id, metaData.postId)}
        backgroundColor={theme.colors.border}
        notificationBadge={
          configReactions && !isKnowledgeRequest ? (
            <SmallAvatarBadge>
              <ReactionEmoji>{mapReactionToKey(metaData.reactionKey, configReactions)?.emote}</ReactionEmoji>
            </SmallAvatarBadge>
          ) : null
        }
        avatarUrl={metaData.avatarUrl ?? ""}
        notificationMessage={
          <NotificationsPostMessage
            displayName={metaData.displayName ?? ""}
            message={
              isKnowledgeRequest
                ? (metaData.otherUserCount || 0) > 0
                  ? t("notifications.krManyUpvotedAnswer", { number: metaData.otherUserCount })
                  : t("notifications.krUpvotedAnswer")
                : t(`notifications.${type}`)
            }
          />
        }
        _createdOn={_createdOn}
        updatedOn={updatedOn}
      />
    );
  },
  [NotificationType.PostReaction]: (result: NotificationData) => {
    const isRead = result.status === NotificationStatus.Read ?? false;
    const isKnowledgeRequest = (result.metadata as BaseMetaData).postType === "knowledgeRequest";
    let metaData;

    if (isKnowledgeRequest) {
      metaData = result.metadata as KnowledgeRequestMetaData;
    } else {
      metaData = result.metadata as PostReactionMetaData;
    }

    const { id, type, linkType, linkId, _createdOn, updatedOn } = result;
    return (
      <NotificationCard
        key={id}
        read={isRead}
        onClick={handleNotificationsNavigation(linkType, id, linkId)}
        backgroundColor={theme.colors.border}
        notificationBadge={
          configReactions && !isKnowledgeRequest ? (
            <SmallAvatarBadge>
              <ReactionEmoji>{mapReactionToKey(metaData.reactionKey, configReactions)?.emote}</ReactionEmoji>
            </SmallAvatarBadge>
          ) : null
        }
        avatarUrl={metaData.avatarUrl ?? ""}
        notificationMessage={
          <NotificationsPostMessage
            displayName={metaData.displayName ?? ""}
            message={
              isKnowledgeRequest
                ? (metaData.otherUserCount || 0) > 0
                  ? t("notifications.krManyUpvotedYourRequest", { number: metaData.otherUserCount })
                  : t("notifications.krUpvotedYourRequest")
                : t(`notifications.${type}`)
            }
          />
        }
        _createdOn={_createdOn}
        updatedOn={updatedOn}
      />
    );
  },
  [NotificationType.BadgeEarned]: (result: NotificationData) => {
    const isRead = result.status === NotificationStatus.Read ?? false;
    const { imageUrl, badgeName } = result.metadata as EarnedBadgeMetaData;
    const { id, type, linkType, linkId, _createdOn, updatedOn } = result;
    return (
      <NotificationCard
        key={id}
        read={isRead}
        onClick={handleNotificationsNavigation(linkType, id, linkId, result.metadata)}
        notificationBadge={null}
        avatarUrl={imageUrl ?? ""}
        notificationMessage={
          <NotificationsBadgePostMessage displayName={badgeName ?? ""} message={t(`notifications.${type}`)} />
        }
        _createdOn={_createdOn}
        updatedOn={updatedOn}
      />
    );
  },
  [NotificationType.Answer]: (result: NotificationData) => {
    const isRead = result.status === NotificationStatus.Read ?? false;
    const { question, avatarUrl } = result.metadata as PostMetaData;

    const { id, type, linkType, linkId, _createdOn, updatedOn } = result;
    return (
      <NotificationCard
        key={id}
        read={isRead}
        onClick={handleNotificationsNavigation(linkType, id, linkId)}
        notificationBadge={null}
        avatarUrl={avatarUrl ?? ""}
        notificationMessage={
          <NotificationsBadgePostMessage displayName={""} message={t(`notifications.${type}`, { question })} />
        }
        _createdOn={_createdOn}
        updatedOn={updatedOn}
      />
    );
  },
  [NotificationType.Reminders]: (result: NotificationData) => {
    const isRead = result.status === NotificationStatus.Read ?? false;
    const { question, avatarUrl } = result.metadata as PostMetaData;

    const { id, type, linkId, _createdOn, updatedOn } = result;
    return (
      <NotificationCard
        key={id}
        read={isRead}
        // Use type here - custom handling
        onClick={handleNotificationsNavigation(type, id, linkId)}
        notificationBadge={null}
        avatarUrl={avatarUrl ?? ""}
        notificationMessage={
          <NotificationsBadgePostMessage displayName={""} message={t(`notifications.${type}`, { question })} />
        }
        _createdOn={_createdOn}
        updatedOn={updatedOn}
      />
    );
  }
});
