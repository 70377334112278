import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { MessageWrapper } from "components/Notifications/NotificationsMessageSection/NotificationsMessageSection.styles";

const NotificationsPostMessage = ({ displayName, message }: { displayName: string; message: string }) => {
  return (
    <MessageWrapper>
      {displayName.length > 0 && <Typography variant="mediumBody1">{displayName}</Typography>}
      <MessageTypography variant="h4">{message}</MessageTypography>
    </MessageWrapper>
  );
};

const NotificationsBadgePostMessage = ({ displayName, message }: { displayName: string; message: string }) => {
  return (
    <MessageWrapper>
      <MessageTypography variant="h4">{message} </MessageTypography>
      <Typography variant="mediumBody1">{displayName}</Typography>
    </MessageWrapper>
  );
};

const NotificationsReAssignedMessage = ({
  displayName,
  message,
  actionMessage
}: {
  displayName: string;
  message: string;
  actionMessage: string;
}) => {
  return (
    <Box display="flex" gap={0.5}>
      <Typography variant="h4">{message} </Typography>
      <Typography variant="mediumBody1">{displayName}</Typography>
      <Typography variant="h4"> {actionMessage}</Typography>
    </Box>
  );
};

const NotificationGroupKudosMessage = ({
  displayName,
  message,
  receiverName
}: {
  displayName: string;
  message: string;
  receiverName: string;
}) => {
  return (
    <MessageWrapper>
      <Typography variant="mediumBody1">{displayName} </Typography>
      <MessageTypography variant="h4">{message} </MessageTypography>
      <Typography variant="mediumBody1">{receiverName} </Typography>
    </MessageWrapper>
  );
};

const NotificationDefaultMessage = ({ message }: { message: string }) => {
  return (
    <MessageWrapper>
      <MessageTypography variant="h4">{message}</MessageTypography>
    </MessageWrapper>
  );
};

export {
  NotificationDefaultMessage,
  NotificationGroupKudosMessage,
  NotificationsBadgePostMessage,
  NotificationsPostMessage,
  NotificationsReAssignedMessage
};

const MessageTypography = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
