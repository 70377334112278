import { TENANT_INFO_URL } from "@talkouttech/portal-shared";
import { DecodedToken, Identity, TenantInfo } from "domain/user";
import jwtDecode from "jwt-decode";

const TOKEN_TYPE_CLAIM = "https://talkout.com/tokenType";
const ANON_TYPE = "anonymousSession";

export const getIdentyFromToken = (token: string): Identity => {
  const decodedToken = jwtDecode<DecodedToken>(token);

  const permissions = decodedToken["permissions"] || [];
  const tenantInfo: TenantInfo = decodedToken[TENANT_INFO_URL] || {};

  return {
    token,
    tenantInfo,
    permissions,
    isAnonymous: decodedToken[TOKEN_TYPE_CLAIM] === ANON_TYPE
  };
};
